import React from 'react'
import Layout from '../components/Layout'

export default class ImpressumPage extends React.Component {
    render() {
        return (
            <Layout>
                <div className="section">
                    <div className="impressum-section" itemScope itemType="http://schema.org/LocalBusiness">
                        <h1 itemProp="name">Agau Edelmetall Agentur Jena</h1>
                        <h2 itemProp="contactPoint">Christian Ferber</h2>
                        <address itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                            <span className="block" itemprop="streetAddress">Quergasse 6</span>
                            <span itemprop="postalCode">07743</span>
                            <span itemprop="addressLocality"> Jena</span>
                            <span className="block" itemprop="telephone">‭+49 (0) 3641 3096124‬</span>
                        </address>
                    </div>
                </div>
            </Layout>
        )
    }
}